import { Transform, Type } from "class-transformer";
import { ProjectDto } from "../projects/project.dto";
import { ExpenseTypeDto } from "../expense-types/expense-type.dto";
import { VehicleDto } from "../vehicles/vehicle.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { CertificationDto } from "@/core/features/certifications/certification.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { toEuros } from "@/utils/numberUtils";
import { AccountingAccountDto } from "@/core/features/accounting-accounts/accounting-account.dto";
import { PaymentMethodDto } from "@/core/features/payment-methods/payment-method.dto";
import { WorksheetDto } from "@/core/features/worksheets/worksheet.dto";
import { FileDto } from "@/core/features/file/file.dto";

export class ExpenseDto extends BaseEntityDto {
  @Type(() => ProjectDto)
  project: ProjectDto;
  projectId: string;

  @Type(() => WorksheetDto)
  worksheet?: WorksheetDto;
  worksheetId?: string;

  @Transform(({ value }) => parseApiDate(value))
  date: Date;

  description: string;

  @Transform(({ value }) => toEuros(value))
  quantity: number;

  @Type(() => ExpenseTypeDto)
  expenseType?: ExpenseTypeDto;
  expenseTypeId?: string;

  @Type(() => UserDto)
  payer?: UserDto;
  payerId?: string;

  @Type(() => PaymentMethodDto)
  paymentMethod: PaymentMethodDto;
  paymentMethodId: string;

  @Type(() => VehicleDto)
  vehicle?: VehicleDto;
  vehicleId?: string;

  chargeToProject: boolean;
  managed: boolean;
  accountancyManaged: boolean;
  accounted: boolean;

  hasInvoice: boolean;
  externalReference?: string;

  @Transform(({ value }) => parseApiDate(value))
  conciliationTime: Date;

  @Type(() => AccountingAccountDto)
  accountingAccount: AccountingAccountDto;
  accountingAccountId: string;

  @Type(() => CertificationDto)
  certification: CertificationDto;
  certificationId: string;

  @Type(() => FileDto)
  files?: FileDto[];

  fileCount: number;

  filesCount() {
    return this.fileCount;
  }

  isEditable(): boolean {
    return !this.isArchived() && !this.accounted;
  }

  isDeletable(): boolean {
    return !this.isArchived() && !this.managed;
  }

  isArchived(): boolean {
    if (!this.project) return true;
    return this.project.isArchived();
  }
}
