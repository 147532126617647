import { HttpRequest } from "../http/http-request";
import { ApiHttpClient } from "../http/api-http-client";
import { ClassConstructor, plainToInstance } from "class-transformer";
import { FindManyResult } from "./find-many-result.dto";
import { CondOperator, RequestQueryBuilder } from "@nestjsx/crud-request";
import { IRequest } from "@/interfaces/IRequest";
import { formatAppDate } from "@/utils/dates/dateFormats";
import { QueryRequestHelper } from "@/core/common/resource-base/query-request.helper";
import { cloneDeep } from "lodash";
import { ArchivedEnum } from "@altertec_gparn/lib";
import { bufferToBlob, downloadPdfBlob } from "@/utils/fileUtils";

export abstract class ResourceBaseService<EntityDto, CreateEntityDto, UpdateEntityDto, RecordEntityDto = unknown> {
  protected EntityDto: ClassConstructor<EntityDto>;
  protected CreateEntityDto: ClassConstructor<CreateEntityDto>;
  protected UpdateEntityDto: ClassConstructor<UpdateEntityDto>;
  protected RecordEntityDto: ClassConstructor<RecordEntityDto>;
  protected resourceUrl: string;

  protected constructor(protected readonly apiHttpClient: ApiHttpClient, protected readonly relativePath: string) {
    this.resourceUrl = "/api" + this.relativePath;
  }

  findAll(request?: IRequest, includeArchived?: boolean, custom?: boolean): Promise<FindManyResult<EntityDto>> {
    const req = cloneDeep(request);
    if (includeArchived != null && !req.filters.archived) {
      req.filters = Object.assign(req.filters, {
        archived: includeArchived ? ArchivedEnum.ALL : ArchivedEnum.ONLY_ACTIVE,
      });
    }
    return this.apiHttpClient
      .request<CreateEntityDto, FindManyResult<EntityDto>>(
        HttpRequest.create({
          url: `${this.resourceUrl}${custom ? "/custom" : ""}`,
          auth: true,
          query: this.getQueryBuilder(req).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(this.EntityDto, findManyResult.data);
        return findManyResult;
      });
  }

  findOne(id: string, join?: string[]): Promise<EntityDto> {
    let url = this.resourceUrl + "/" + id;

    if (join && join.length > 0) {
      url += "?join=" + join.join("&join=");
    }

    return this.apiHttpClient
      .request<CreateEntityDto, EntityDto>(HttpRequest.create({ url: url, auth: true }))
      .then((entityDto) => plainToInstance(this.EntityDto, entityDto));
  }

  create(body: CreateEntityDto): Promise<EntityDto> {
    return this.apiHttpClient.request<CreateEntityDto, EntityDto>(
      HttpRequest.create({
        url: this.resourceUrl,
        method: "POST",
        auth: true,
        body: plainToInstance(this.CreateEntityDto, body, { excludeExtraneousValues: true }),
      })
    );
  }

  createMany(body: CreateEntityDto[]): Promise<EntityDto> {
    return this.apiHttpClient.request<{ bulk: CreateEntityDto[] }, EntityDto>(
      HttpRequest.create({
        url: this.resourceUrl + "/bulk",
        method: "POST",
        auth: true,
        body: { bulk: plainToInstance(this.CreateEntityDto, body, { excludeExtraneousValues: true }) },
      })
    );
  }

  update(id: string, body: UpdateEntityDto): Promise<EntityDto> {
    return this.apiHttpClient.request<UpdateEntityDto, EntityDto>(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id,
        method: "PATCH",
        auth: true,
        body: plainToInstance(this.UpdateEntityDto, { ...body, id }, { excludeExtraneousValues: true }),
      })
    );
  }

  remove(id: string): Promise<void> {
    return this.apiHttpClient.request<void, void>(
      HttpRequest.create({ url: this.resourceUrl + "/" + id, auth: true, method: "DELETE", type: "text" })
    );
  }

  getRecords(request?: IRequest): Promise<FindManyResult<RecordEntityDto>> {
    return this.apiHttpClient
      .request<CreateEntityDto, FindManyResult<RecordEntityDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "-records",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(this.RecordEntityDto, findManyResult.data, {
          excludeExtraneousValues: false,
        });
        return findManyResult;
      });
  }

  async downloadExcel(request?: IRequest, path = "/excel", excelName = ""): Promise<void> {
    const data = await this.apiHttpClient.request<CreateEntityDto, string>(
      HttpRequest.create({
        url: this.resourceUrl + path,
        method: "GET",
        auth: true,
        query: request ? this.getQueryBuilder(request).query() : null,
      })
    );
    if (!data) {
      throw new Error("Excel not found");
    }

    const link = document.createElement("a");
    link.href = URL.createObjectURL(
      bufferToBlob(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    );
    link.download = `GPARN-${excelName}-${formatAppDate(new Date(), false)}-${excelName}.xlsx`;
    link.click();
  }

  async downloadPdf(id: string, path = "/pdf", filename = ""): Promise<void> {
    const data = await this.apiHttpClient.request<CreateEntityDto, string>(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + path,
        method: "GET",
        auth: true,
      })
    );

    downloadPdfBlob(data, filename);
  }

  async createTopic(id: string): Promise<EntityDto> {
    return this.apiHttpClient.request<null, EntityDto>(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/save-topic",
        auth: true,
        method: "PATCH",
        type: "text",
      })
    );
  }

  async validateUniqueness(value: string | number | Date, field: string, id?: string) {
    if (!value) return true;

    const request: IRequest = { filters: { [field]: String(value) } };

    if (id) {
      request.customAndFilters = [{ field: "id", value: id, operator: CondOperator.NOT_EQUALS }];
    }

    const { data } = await this.findAll(request);

    return !data.length;
  }

  async getMine(request: IRequest = {}): Promise<FindManyResult<EntityDto>> {
    return this.apiHttpClient
      .request<EntityDto, FindManyResult<EntityDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/mine",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(this.EntityDto, findManyResult.data);
        return findManyResult;
      });
  }

  async downloadAllFiles(request: IRequest = {}) {
    return this.apiHttpClient.request<null, string[]>(
      HttpRequest.create({
        url: this.resourceUrl + "/download-all",
        auth: true,
        query: this.getQueryBuilder(request).query(),
      })
    );
  }

  protected getQueryBuilder(inputRequest: IRequest): RequestQueryBuilder {
    return new QueryRequestHelper(cloneDeep(inputRequest)).getRequest();
  }
}
