import { Expose, plainToInstance, Transform, Type } from "class-transformer";
import { ConsumableDto } from "@/core/features/consumables/consumable.dto";
import { get } from "lodash";

export class ConsumableHistoryDto {
  @Expose()
  @Transform(({ obj }) => get(obj, "Consumable_id"))
  id: string;

  @Expose()
  @Transform(({ obj }) => {
    const consumableKeys = Object.keys(obj).filter((key) => key.startsWith("Consumable_"));
    const consumableData = consumableKeys.reduce((acc, key) => {
      const newKey = key.replace("Consumable_", "");
      Object.assign(acc, { [newKey]: obj[key] });
      return acc;
    }, {});
    return plainToInstance(ConsumableDto, consumableData);
  })
  consumable: ConsumableDto;

  @Expose()
  @Type(() => Number)
  totalQuantity: number;
}
