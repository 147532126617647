import { Expose } from "class-transformer";

export class UpdateOperativeUnitDto {
  @Expose()
  id: string;

  @Expose()
  code: string;

  @Expose()
  name: string;

  @Expose()
  codification: string;

  @Expose()
  color: string;
}
