import { ComponentPublicInstance } from "@vue/runtime-core";
import { formatApiDate } from "@/utils/dates/dateFormats";
import { ProjectSchedulesUserDto } from "@/core/features/project-schedule-users/project-schedules-user.dto";
import { addDays, eachDayOfInterval } from "date-fns";
import { TimeOffDto } from "@/core/features/times-off/time-off.dto";
import { WorksheetDto } from "@/core/features/worksheets/worksheet.dto";
import { MyCalendarEventType } from "@/modules/calendar/types/MyCalendarEventType";
import { toLocaleTimezone } from "@/utils/dates/dateUtils";
import { OnCallDto } from "@/core/features/on-calls/on-call.dto";
import { IRequest } from "@/interfaces/IRequest";
import { CondOperator } from "@nestjsx/crud-request";

export class CalendarsPresenter {
  async getData(component: ComponentPublicInstance, store: string, userId: string, dates: Date[], isMine?: boolean) {
    component.$store.dispatch(`${store}/clear`);
    const from = this.getFirstDate(dates);
    const to = this.getLastDate(dates);
    await Promise.all([
      this.getTimesOff(component, store, userId, from, to, isMine),
      this.getOnCalls(component, store, userId, from, to, isMine),
      this.getSchedule(component, store, userId, from, to),
      this.getWorksheets(component, store, userId, from),
    ]);
  }

  private async getSchedule(
    component: ComponentPublicInstance,
    store: string,
    userId: string,
    from: string,
    to: string
  ) {
    const scheduledMe = (
      await component.$projectScheduleUsersResource.getMySchedules(userId, {
        join: ["project", "project.workplace"],
        filters: { from: from, to: to },
        rowsPerPage: 500,
      })
    ).data;

    scheduledMe.forEach((schedule: ProjectSchedulesUserDto) =>
      this.registerElement(component, store, schedule, schedule.projectId, { date: schedule.date }, "project")
    );
  }

  async getTimesOff(
    component: ComponentPublicInstance,
    store: string,
    userId: string,
    from: string,
    to: string,
    isMine: boolean
  ) {
    const request = {
      join: ["timeOffType"],
      filters: {
        from: from,
        to: to,
        startDateField: "start",
        endDateField: "end",
        "timeOffHasUsers.userId": userId,
      },
      rowsPerPage: 500,
    };
    const timesOff = (
      isMine
        ? await component.$timesOffResource.getMyTimesOff(userId, request)
        : await component.$timesOffResource.findAll(request)
    ).data;

    timesOff.forEach((timeOff: TimeOffDto) =>
      this.registerElement(
        component,
        store,
        timeOff,
        timeOff.id,
        {
          start: timeOff.start,
          end: timeOff.end,
        },
        "time-off"
      )
    );
  }

  async getOnCalls(
    component: ComponentPublicInstance,
    store: string,
    userId: string,
    from: string,
    to: string,
    isMine: boolean
  ) {
    const request: IRequest = {
      join: ["onCallType"],
      filters: {
        from: from,
        to: to,
        startDateField: "date",
        endDateField: "date",
      },
      rowsPerPage: 500,
    };

    if (!isMine) {
      request.customAndFilters = [{ field: "onCallHasUsers.userId", operator: CondOperator.IN, value: [userId] }];
    }

    const onCalls = (
      isMine
        ? await component.$onCallsResource.getMyOnCalls(request)
        : await component.$onCallsResource.findAll(request)
    ).data;

    onCalls.forEach((onCall: OnCallDto) =>
      this.registerElement(component, store, onCall, onCall.id, { date: onCall.date }, "on-call")
    );
  }

  async getWorksheets(component: ComponentPublicInstance, store: string, userId: string, from: string) {
    const worksheets: WorksheetDto[] = await component.$worksheetsResource.getMonthlyWorksheetsByUser(userId, from);

    worksheets.forEach((worksheet: WorksheetDto) =>
      this.registerElement(component, store, worksheet, worksheet.id, { date: worksheet.date }, "worksheet")
    );
  }

  registerElement(
    component: ComponentPublicInstance,
    store: string,
    object: TimeOffDto | ProjectSchedulesUserDto | WorksheetDto | OnCallDto,
    id: string,
    dates: { date?: Date; start?: Date; end?: Date },
    type: MyCalendarEventType
  ) {
    const isIntervalDates = dates.start && dates.end;
    if (isIntervalDates) {
      eachDayOfInterval({ start: toLocaleTimezone(dates.start), end: toLocaleTimezone(dates.end) }).forEach(
        (date: Date) =>
          component.$store.dispatch(`${store}/createEvent`, this.getRegisterObject(object, id, date, type))
      );
    } else {
      component.$store.dispatch(`${store}/createEvent`, this.getRegisterObject(object, id, dates.date, type));
    }
  }

  private getFirstDate(dates: Date[]) {
    return formatApiDate(addDays(dates[0], -1));
  }

  private getLastDate(dates: Date[]) {
    return formatApiDate(addDays(dates[dates.length - 1], 1));
  }

  private getRegisterObject(
    object: TimeOffDto | ProjectSchedulesUserDto | WorksheetDto | OnCallDto,
    id: string,
    date: Date,
    type: MyCalendarEventType
  ) {
    return {
      object,
      id,
      date: toLocaleTimezone(date),
      type,
      position: 0,
    };
  }
}
